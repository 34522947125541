.categoryTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font: normal normal normal 16px/19px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.categoryUnderline {
  min-width: 60%;
  white-space: nowrap;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 16px;
  border-bottom: 2px solid #006ad4;
}

.categoryFilesColumn {
  // border-right: 2px solid #707070;
  &:before {
    content: '';
    position: absolute;
    right: 0;
    top: 30px;
    height: 70%;
    width: 1px;
    border-right: 2px solid #707070;
    // margin-top:1.2em; /*move the border below the text*/
  }
}
