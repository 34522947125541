.box {
  border: 1px solid #b4b4b4;
  border-radius: 5px;
  padding: 0.75rem;
  margin: 0.5rem !important;
}

.item {
  display: flex;
  align-items: center;
}

@media print {
  .dataBlockContainer {
    display: block !important;
  }

  .dataBlock {
    break-inside: avoid;
  }
}
