.charting {
  display: flex;
  height: max-content;
  min-height: 60vh;
}

.chart {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-height: 100%;
  height: calc(100vh - 220px);
}

.margin {
  margin-top: 15px;
}

.field {
  display: flex;
  justify-content: center;
}
