.file-archive-item {
  font-weight: 500;
  cursor: pointer;
}

.file-archive-item:hover {
  text-decoration: underline;
}

.file-archive-item-readon-only {
  font-weight: 500;
  color: #aaa;
}

.report-section {
  text-overflow: ellipsis;
  overflow: hidden;
}
