.charting {
  display: flex;
  height: max-content;
  min-height: 60vh;
  padding: 0 10px;
  border-right: 1px solid gray;
}

.config {
  min-width: 300px;
  padding: 0 10px;
  border-right: 1px solid gray;
}

.chart {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-height: 100%;
}

.margin {
  margin-top: 15px;
}

.field {
  display: flex;
  justify-content: center;
}

.dataBlock {
  margin: 8px 0;
}
