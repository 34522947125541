.dot {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  width: 46px;
  background-color: #bbb;
  border-radius: 50%;
}

.fileIcon {
  color: white;
  position: relative;
  top: 2px;
}
