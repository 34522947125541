#header-logo {
  height: 55px;
  vertical-align: middle;
  -moz-vertical-align: middle;
  padding-right: 2rem;
}

#header-title {
  position: absolute;
  margin-right: 0.5rem;
  top: 20px;
}

#header-title span {
  position: relative;
  bottom: 4px;
}

.header-line {
  display: flex;
}

.header-separator {
  // border-right: 1px solid #b2b2b2;
  padding-right: 1rem;
}

.header-section {
  padding-left: 1rem;
  display: flex;
  align-items: center;
  z-index: 1;
}

.header-section-inline {
  display: flex;
  align-items: center;
}

.header-action {
  padding-left: 0.5rem;
}

.fileIcon {
  transform: scale(1.3);
}

.actionIconInactive {
  color: #006ad4;
}

.actionIconActive {
  color: #212121;
}
