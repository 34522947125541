body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 0.875rem !important;
  line-height: 1.43 !important;
  letter-spacing: 0.01071em !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.page-section {
  margin-bottom: 34px;
}

.paper-title {
  font-weight: bold !important;
  text-transform: uppercase;
  font-size: 1rem !important;
}

.label-section {
  font-size: 18px !important;
  font-weight: bold !important;
}

.expand-section {
  color: #006ad4;
  text-decoration: underline;
}

.label-title {
  break-after: avoid;
  font-weight: bold !important;
  text-transform: uppercase;
}

.label-title-nocase {
  break-after: avoid;
  font-weight: bold !important;
}

.label-title-caption {
  font-size: 0.65rem !important;
}

.label-title-desc {
  font-size: 14px !important;
  text-transform: lowercase;
  color: gray;
}

@media screen and (max-width: 414px) {
  .btn-responsive {
    width: 100% !important;
  }
}

#main-paper {
  color: black;
}

h6 {
  font-weight: bold !important;
}

hr {
  width: 80%;
  margin: 0 auto !important;
}

.paper-title {
  font-weight: bold !important;
  text-transform: uppercase;
  font-size: 1rem !important;
}

.label-title {
  font-weight: bold !important;
  text-transform: uppercase;
}

.label-title-nocase {
  font-weight: bold !important;
}

.label-title-caption {
  font-size: 0.65rem !important;
}

.MuiSelect-outlined {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  font-size: 0.8rem !important;
}

.MuiOutlinedInput-input {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  font-size: 0.8rem !important;
}

.MuiOutlinedInput-input[readOnly] {
  background-color: #f3f3f3;
}

.MuiInputLabel-root {
  font-size: 0.8rem !important;
}

.MuiInputBase-input {
  font-size: 0.8rem !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.MuiAutocomplete-inputRoot {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  // padding-right: 0px !important;
}

.promoted_to_proposal_column {
  display: flex;
  justify-content: center;
}

@media print {
  button {
    display: none !important;
  }
}

textarea {
  font-family: inherit;
  // display: block;
  vertical-align: top;
}

.Mui-selected {
  opacity: 1 !important;
}

.MuiTab-root {
  color: black !important;
}

.dialogGanttChart .ganttChart .gantt-container {
  height: 70vh;
  border: 1px solid lightgray;
}

.gantt-container .popup-wrapper {
  width: fit-content;
  min-width: 200px;
  white-space: nowrap;
  padding: 10px !important;
  background: rgba($color: lightgray, $alpha: 1) !important;
  color: gray !important;
  border: 1px solid gray;
}

.gantt_red .bar {
  fill: red !important;
}

.gantt_green .bar {
  fill: green !important;
}

.gantt_blue .bar {
  fill: blue !important;
}

.gantt_orange .bar {
  fill: orange !important;
}
