.linkStyle {
  font-size: 12px;
  line-height: 15px;
  color: #006ad4;
  opacity: 1;
  cursor: pointer;
}

.FileInfoComponent {
  padding-left: 20px;
  padding-right: 20px;
}

.fileInfo {
  width: 100%;
  padding-left: 1rem;
  word-break: break-all;
  word-wrap: break-word;
}

.documentLink {
  color: #006ad4;
  text-decoration: none;
}

.documentLink:hover {
  text-decoration: underline;
}
