.FileInfoComponent {
  padding-left: 20px;
  padding-right: 20px;
}

.fileInfo {
  width: 100%;
  padding-left: 1rem;
  word-break: break-all;
  word-wrap: break-word;
}

.documentLink {
  color: #006ad4;
  text-decoration: none;
}

.documentLink:hover {
  text-decoration: underline;
}

.approvedLabel {
  font-size: 0.7rem;
  width: fit-content;
  background-color: green;
  color: white;
  border-radius: 3px;
  padding: 0 7px;
  margin: 5px;
}

.reviewRequiredLabel {
  font-size: 0.7rem;
  width: fit-content;
  background-color: blue;
  color: white;
  border-radius: 3px;
  padding: 0 7px;
  margin: 5px;
}
