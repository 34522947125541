.label_grid {
  margin: auto 0 !important;
}

.expandSection {
  border-bottom: 2px solid #006ad4;
}

.expandText {
  color: #006ad4;
}

@media print {
  .dataBlockContainer {
    display: block !important;
  }

  .dataBlock {
    break-inside: avoid;
  }
}
