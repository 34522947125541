.linkStyle {
  font-size: 12px;
  line-height: 15px;
  color: #006ad4;
  opacity: 1;
  cursor: pointer;
}

.statusLine {
  display: flex;
}
