@media print {
  :global(button.MuiTab-root) {
    display: unset !important;
  }
  .box {
    margin: 0;
    color: #000;
    background-color: #fff;
  }
}
