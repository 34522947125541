.drag-icon {
  vertical-align: bottom;
}

.dragging {
  background-color: #ececec;
}

¸.dragging .MuiPaper-root {
  opacity: 0.5;
}

.dragged .MuiPaper-root {
  opacity: 1;
}
