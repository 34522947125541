.head {
  display: flex;
  align-items: baseline;
  justify-content: left;
  background-color: rgb(118, 147, 60);
  color: white;
  padding: 0px 5px;
}

.resultHead {
  display: flex;
  align-items: baseline;
  justify-content: right;
  background-color: rgb(118, 147, 60);
  color: white;
  padding: 0px 5px;
}

.resultColumn {
  display: flex;
  align-items: baseline;
  justify-content: right;
  padding: 0px 5px;
  border-bottom: 1px solid lightgray;
}

.keyColumn {
  display: flex;
  align-items: baseline;
  justify-content: left;
  padding: 0px 5px;
  border-bottom: 1px solid lightgray;
}
